<template>
  <main class="page-points page-notes-point p-0 d-flex flex-column">
    <div class="nav-container">
      <div class="container">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.show' }">Dati</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.edit' }">Modifica</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.visea' }">Modulo ViSEA</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.visea.graph' }">Modulo ViSEA (Grafo)</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="{ name: 'projects.tutorial' }" aria-current="page">Tutorial</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-grow-1 container py-5">
      <div class="nav nav-pills tutorial-nav" id="nav-vertical-tab" role="tablist" aria-orientation="vertical" :class="notes.length > 0 ? 'justify-content-between' : ''">
        <div class="d-flex">
          <a v-for="(note, i) in notes" class="nav-link" :class="{ 'active': !$modals.preview && !newNote && selectedNoteId === note.id }" id="nav-vertical-tab1-tab" data-toggle="tab" @click.prevent="editNote(note.id)" role="tab" aria-controls="nav-vertical-tab1" aria-selected="true" :key="note.id">
            Tutorial <span class="tutorial-number">#{{ i + 1 }}</span>
          </a>
        </div>
        <a class="nav-link m-0 add-new" :class="{ 'active': !$modals.preview && newNote }" @click.prevent="addNote">Aggiungi</a>
        <a class="nav-link m-0 preview" :class="{ 'active': $modals.preview }" @click.prevent="$openModal('preview')">Preview</a>
      </div>
      <div class="tab-content mt-5" v-if="newNote">
        <TutorialNoteForm :feedback.sync="feedback" @submitForm="saveNewNote" />
      </div>
      <div class="tab-content mt-5" v-else-if="note">
        <TutorialNoteForm :feedback.sync="feedback" edit :note="note" @submitForm="updateNote" @removeNote="removeActiveNote"/>
      </div>
      <div class="tab-content mt-5 text-center" v-else>
        <template v-if="notes.length === 0">Aggiungi un nuovo contenuto.</template>
        <template v-else>Seleziona un contenuto esistente o aggiungine uno nuovo.</template>
      </div>
    </div>
    <InfoModal v-if="$modals.preview" :title="project.name" @close="$closeModal('preview')">
      <div class="mb-4" v-for="note in notes" :key="note.id">
        <div v-for="image in note.images" :key="image.id">
          <img class="img-fluid" style="max-width: 100%" :src="image.url" alt="">
        </div>

        <p>{{ note.description }}</p>
      </div>
    </InfoModal>
  </main>
</template>

<script>

import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';
import $api from '@/libs/OAuth2/services/api';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';

export default {
  mixins: [rolesMixin, confirmWithModalMixin, modalsManagerMixin],
  components: {
    TutorialNoteForm: () => import('@/views/components/Form/TutorialNoteForm'),
    InfoModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
      newNote: null,
      selectedNoteId: null,
      notes: [],
    };
  },
  computed: {
    note () {
      return this.notes.find(n => n.id === this.selectedNoteId);
    },
  },
  methods: {
    fetchResults () {
      return $api.fetchProjectTutorial(this.project.id)
        .then(res => {
          this.notes = res?.data?.notes || [];
        })
        .catch(() => this.$log.error)
      ;
    },
    editNote (id) {
      this.selectedNoteId = id;
      this.feedback = null;
      this.newNote = null;
    },
    addNote () {
      this.newNote = {
        description: null,
        images: [],
      };
    },
    saveNewNote (noteForm) {
      const note = { ...noteForm };

      this.$api.createProjectTutorial(this.project.id, note)
        .then(() => {
          this.feedback = true;
          return this.fetchResults();
        })
        .then(() => {
          this.editNote(null);
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    updateNote (noteForm) {
      const note = { ...noteForm };

      this.$api.updateProjectTutorial(note.id, note)
        .then(() => {
          this.feedback = true;
          return this.fetchResults();
        })
        .then(this.abortEditNote)
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    removeActiveNote () {
      this.confirm('Cancellare il contenuto e tutte le immagini collegate?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          if (!this.note) {
            return;
          }

          this.$api.removeProjectTutorial(this.note.id)
            .then(() => {
              return this.fetchResults();
            })
            .then(() => {
              this.editNote(null);
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    abortEditNote () {
      this.editNote(null);
    },
  },
  mounted () {
    this.fetchResults();
  },
};

</script>

<style lang="scss">
.tutorial-nav {
  .nav-link {
    background: rgba($body-color, .4);
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .04rem;
    border: 0;
    font-size: 14px;
    margin-right: 10px;

    .tutorial-number {
      background: rgba($body-color, .3);
      display: inline-block;
      padding: 0 3px;
      border-radius: 3px;
    }

    &:hover {
      background: rgba($body-color, .5);
      color: $white;
      cursor: pointer;
    }

    &.active {
      background: $primary;

      .tutorial-number {
        background: rgba(darken($primary, 10%), 1);
      }
    }

    &.add-new.active {
      background: rgba($body-color, .65);
    }
  }
}
</style>
